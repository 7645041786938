import {useParams} from "react-router";
import {Slider} from "../../components/Slider";
import React from "react";

export function CatalogDetail(props) {

    const params = useParams();

    if(props.data != false) {

        const id = params.detailId;
        const element = props['data']['elements'][id];

        return (
            <div className='product-detail wrapper'>
                {(element.photo != false) &&
                    <Slider aspectRatio={1} data={element.photo}/>
                }

                <div className='product-labels'>
                {element.labels?.map((label,index) => (
                    <div className={'label label-'+label.type}>{label.title}</div>
                ))}
                </div>

                {element.title &&
                <div className='product-name'>{element.title.replace(/&quot;/g, '"')}</div>
                }

                {element.sku ? (
                    <div className='buy-block product-sku-list'>
                        {element.sku.map((sku)=>(
                            <div key={sku.id} className='product-sku'>
                                {sku?.params?.map((param,index) => (
                                    <div key={index} className='product-sku-param'>
                                        <div className='product-sku-param-title'>{param.title}</div>
                                        <div className='product-sku-param-value'>{param.value}</div>
                                    </div>
                                ))}
                                <div className='dotted'></div>
                                <div className='product-price'>
                                    {Intl.NumberFormat("ru").format(sku.price)}&nbsp;₽
                                </div>
                                <div className='product-old-price'>
                                {sku.old_price &&
                                    <span>{Intl.NumberFormat("ru").format(sku.old_price)}&nbsp;₽</span>
                                }
                                </div>


                                {(props.basket[id+"#"+sku.id]?.count > 0) ? (
                                        <div className='block-count-buy'>
                                            <button className='btn btn-buy' onClick={()=>props.onBasket(element,sku.id,(props.basket[id+"#"+sku.id]?.count-1))}>-</button>
                                            <div className='buy-count'>{props.basket[id+"#"+sku.id]?.count}</div>
                                            <button className='btn btn-buy' onClick={()=>props.onBasket(element,sku.id,(props.basket[id+"#"+sku.id]?.count+1))}>+</button>
                                        </div>
                                    ) : (
                                        <button className='btn btn-buy' onClick={()=>props.onBasket(element,sku.id,1)}>Добавить</button>
                                    )
                                }

                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='buy-block'>
                        <div className='product-sku'>
                            <div className='product-sku-param'>
                                <div className='product-sku-param-title'>вес</div>
                                <div className='product-sku-param-value'>{element.weight}</div>
                            </div>
                            <div className='dotted'></div>

                            <div className='product-price'>
                                {Intl.NumberFormat("ru").format(element.price)}&nbsp;₽
                            </div>
                            <div className='product-old-price'>
                                {element.old_price &&
                                <span>{Intl.NumberFormat("ru").format(element.old_price)}&nbsp;₽</span>
                                }
                            </div>
                            {(props.basket[id]?.count > 0) ? (
                                <div className='block-count-buy'>
                                    <button className='btn btn-buy' onClick={()=>props.onBasket(element,false,(props.basket[id]?.count-1))}>-</button>
                                    <div className='buy-count'>{props.basket[id]?.count}</div>
                                    <button className='btn btn-buy' onClick={()=>props.onBasket(element,false,(props.basket[id]?.count+1))}>+</button>
                                </div>
                            ) : (
                                <button className='btn btn-buy' onClick={()=>props.onBasket(element,false,1)}>Добавить</button>
                            )
                            }
                        </div>
                    </div>
                )}


                <div className='product-description'>
                    {element.composition &&
                    <div className='product-composition'>
                        <span className='title'>Состав:&nbsp;</span>
                        <span>{element.composition}</span>
                    </div>
                    }
                    {(element.proteins || element.fats || element.energy_kkal || element.energy_kdz) &&
                    <div className='product-nutritional-value'>
                        <div className='title'>
                            Пищевая ценность в 100г продукта
                        </div>
                        {element.proteins &&
                        <div className='product-nutritional-item'>
                            <div className='product-nutritional-item-title'>Белки</div>
                            <div className='product-nutritional-item-dotted'></div>
                            <div className='product-nutritional-item-value'>{element.proteins}</div>
                            <div className='product-nutritional-item-measure'>г.</div>
                        </div>
                        }
                        {element.fats &&
                        <div className='product-nutritional-item'>
                            <div className='product-nutritional-item-title'>Жиры</div>
                            <div className='product-nutritional-item-dotted'></div>
                            <div className='product-nutritional-item-value'>{element.fats}</div>
                            <div className='product-nutritional-item-measure'>г.</div>
                        </div>
                        }
                        {element.carbs &&
                        <div className='product-nutritional-item'>
                            <div className='product-nutritional-item-title'>Углеводы</div>
                            <div className='product-nutritional-item-dotted'></div>
                            <div className='product-nutritional-item-value'>{element.carbs}</div>
                            <div className='product-nutritional-item-measure'>г.</div>
                        </div>
                        }
                        {(element.energy_kkal || element.energy_kdz) &&
                        <div className='product-nutritional-item'>
                            <div className='product-nutritional-item-title'>Энергетическая ценность</div>
                            <div className='product-nutritional-item-dotted'></div>
                            <div className='product-nutritional-item-value'>
                                {element.energy_kkal &&
                                <span>{element.energy_kkal}&nbsp;ккал</span>
                                }
                                {(element.energy_kkal && element.energy_kdz) &&
                                <span>/</span>
                                }
                                {element.energy_kdz &&
                                <span>{element.energy_kdz}&nbsp;кДж</span>
                                }
                            </div>
                            <div className='product-nutritional-item-measure'>г.</div>
                        </div>
                        }
                    </div>
                    }
                    {element.storage_conditions &&
                    <div className='product-storage-conditions'>
                        <span className='title'>Срок годности и условия хранения:&nbsp;</span>  {element.storage_conditions}
                    </div>
                    }
                    {element.cooking &&
                    <div className='product-storage-conditions'>
                        <span className='title'>Рекомендации по приготовлению:&nbsp;</span>  {element.cooking}
                    </div>
                    }

                </div>



            </div>
        )
    } else {
        return (
            <div>
                Загрузка...
            </div>
        )
    }

}
