export function Delivery(props) {
    return (
        <div className='delivery-content'>
            <div className='content wrapper flex-1'>
                <h3>Описание доставки</h3>
                <p>
                    Мы доставляем заказы без выходных с 8 утра до 22 часов вечера.
                    Мы доставим заказ в удобный вам часовой интервал в любое удобное время с 8 утра до 22 часов вечера.
                </p>
                <p>
                    Сумма минимального заказ составляет 2.500 руб.
                    Доставка в пределах МКАД составляет 290 руб., а при заказе от 5000 руб будет бесплатной.
                    Доставка по Подмосковью составляет 490 руб при сумме заказа от 2500 руб, или будет бесплатной при заказе от 5000 руб.
                </p>
                <p>
                    Всю необходимую информацию уточнит наш диспетчер по телефону, а затем вы получите два уведомления на мобильный телефон или на электронную почту : сразу после оформления заказа и после того как он будет готов к отправке.
                </p>

            </div>
            <div className='delivery-items'>
                <div className='wrapper '>
                    <div className='row'>
                        <div className='flex-1 delivery-item-name'>
                            <b>СУММА ЗАКАЗА</b>
                        </div>
                        <div>
                            <b>СТОИМОСТЬ</b>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='flex-1 delivery-item-name'>2 500 ₽ - 5 000 ₽</div><div>290 ₽</div>
                    </div>
                    <div className='row'>
                        <div className='flex-1 delivery-item-name'>От 5000 ₽</div><div className='flex-none color-main'><b>0 ₽</b></div>
                    </div>
                </div>

            </div>
        </div>

    )
}